/*
    This program is designed to identify preferences for video (YouTube) content. The program uses
    the multiple-stimulus-without-replacement (MSWO) preference assessment methodology (Carr,
    Nicolson, & Higbee, 2000; Deleon & Iwata, 1996). The design and functions are based on the
    web-based MSWO program described in Curiel, Curiel, Li, Deochand, and Poling (2018)
    Copyright (C) 2019 Antonio Adame assigned to Board of Regents of the University of Texas System.

    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/>. */

$primary-color: #006882;
$panel-color: white;
$break-small: 320px;
$break-large: 1024px;
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
}



* {
  margin: 0; 
  padding: 0;
}
html, body, .App {
  height: 100%;
}
body {
  background-color: $primary-color;
  font-family: 'Roboto', sans-serif;
  text-align: center;

  h1.page-title {
    text-align: center;
    margin-top: 20px;

    font-weight: lighter;
    color: whitesmoke;
    font-size: 50px;

    letter-spacing: 10px;
    text-shadow: 5px 5px rgba(0,0,0,0.16);
  }
  #root {height: 100%;}
}
.ref-link {
  display: inline-block;
  margin: 3.5rem;
}
.Home-content {
  height: 100%;
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.25fr;
  grid-template-areas: 
    ". videoList ."
    ". paramList ."
  ;
}
.panel {
  background-color: $panel-color;
  
  margin: 30px;

  border: solid 5px rgba(112,112,112,0.5);
  border-radius: 10px;
  box-shadow: 15px 15px  rgba(0,0,0,0.2);

  position: relative;
}
.panel-title {
  font-weight: lighter;
  color: #707070;
  font-size: 50px;
  
  text-align: center;
  margin: 10px 0 10px 0;
  span { font-weight: bold; }
}
.AssessmentCompleteScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0px;
}
.panel-subtitle {
  font-weight: lighter;
  color: #707070;
  font-size: 35px;
  
  text-align: center;
  margin: 30px 0 10px 0;
  span { font-weight: bold; }
}

.video-list {
  grid-area: videoList;
  text-align: center;

  ul {
    display: flex;
    margin: auto;
    width: 20rem;

    list-style-type: none;
    margin-bottom: 10px;
  }
  form {
    text-align: center;
    margin-bottom: 30px;
  }
}
.VideoCheckList {
  display: flex;
  flex-wrap: wrap;
  * {
    flex: 1 1 160px;
  }
}
.param-list {
  grid-area: paramList;
  text-align: center;
  form {
    margin-bottom: 30px;
  }
}
// Refers to the checklist video item
.video-item {
  &:hover {
    cursor: default;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
}
a {text-decoration: none;}

.router-continue {
  text-align: center;
}
.cont-btn {
  display: inline-block;
}

.showcase-message {
  text-align: center;
  margin-top: 20px;

  font-weight: lighter;
  color: whitesmoke;
  font-size: 30px;

  letter-spacing: 10px;
  text-shadow: 5px 5px rgba(0,0,0,0.16);
}

// parameter sliders
.ParameterInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.input-range {
  position: relative;
  margin: 50px;
  width: 100%;
  left: -50px;
}
.input-range__label-container {
  font-size: 15px;
}
.row.slider {
  width: 50%;
}


// video showcase
.VideoItems {
  width: 100%;
  height: 100%;
}
.VideoGallery {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
}
.MainPlayer {
  z-index: 10;

  background-color: rgba(0,0,0,0.8);
  margin: 0 auto !important;
  width: 100%;
  height: 100%;
  position: fixed !important;

  align-items: center;
  justify-content: center;
}
.VideoShowcase {
  height: 100%;
  text-align: center;
}

.VideoContainer {
  position: relative;
  transition: left 1s;
}

.disable-click {
  pointer-events: none;
}

#idleTimer {
  color: whitesmoke;
  font-size: 50px;
}
.ThumbnailContainer {
  z-index: 1;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;

  width: 21em;
  height: 16em;

  margin: 1%;

  @media (max-width: 1700px) {
    width: 11em;
    height: 9em;
  }
  @media (max-width: 768px) {
    width: 8em;
    height: 7em;
  }

  &:hover {
    cursor: pointer;
  }
}

//Charts
.preference-results-options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  button {
    width: 16rem;


  }
}
.PreferenceResults {
 
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
.start-over-link {
  text-decoration: none;
  color:white;
}
#Charts {
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: row;
  
  margin: 0;
  @include respond-to(medium-screens) { flex-direction: column }
  @include respond-to(handhelds) { 
    flex-direction: column;
    justify-content: center;
   }
}
.chart {
  border-radius: 10px;
  padding: 30px;

  box-shadow: 15px 15px rgba(0,0,0,0.16);
  border: solid 5px rgba(112,112,112,0.5);

  width: 30em;
  height: 20em;
  background: whitesmoke;
  margin: 50px;

  @include respond-to(handhelds) { 
    width: 100%; 
    margin: 0 0; 
    box-shadow: none;
    border-radius: 0;
  }
  @include respond-to(medium-screens) { 
    width: 100%; 
    margin: 20px 0; 
    box-shadow: none;
    border-radius: 0;
  }

}